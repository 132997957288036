.contact {
  min-height: 100vh;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 2%;
  padding-bottom: 2%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f9f9f9;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.contact h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #333;
}

.contact p {
  font-size: 1.2em;
  margin: 10px 0;
  color: #555;
}

.contact-info {
  margin: 20px 0;
}

.contact-info p {
  font-size: 1.1em;
  margin: 10px 0;
}

.contact a {
  color: #0073e6;
  text-decoration: none;
}

.contact a:hover {
  text-decoration: underline;
}