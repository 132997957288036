:root {
  --primary-color: #555;
  --text-color: #555;
  --background-color: #fff;
  --box-shadow-color: rgba(0, 0, 0, 0.1);
}

.timeline {
  min-height: 100vh;
  position: relative;
  padding: 20px 0;
  padding-top: 5%;
  padding-bottom: 5%;
  padding-left: calc(5% + 95px);
}

/* controls the line itself */
.timeline::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 5px;
  margin-left: calc(5% + 95px);
  width: 2px;
  background: var(--primary-color);
}

.timeline-dot {
  position: absolute;
  top: 35px;
  background: var(--primary-color);
  border-radius: 50%;
  z-index: 2;
  /* Adjust this value to align the dot with the line */
  left: 0px;
  /* Adjust the size of the dot */
  width: 12px;
  height: 12px;
}

.timeline-icon {
  height: auto;
  width: 50px;
}

.timeline-icon-container {
  position: absolute;
  /* Adjust the top position to align with the dot */
  top: 20px;
  /* Adjust the left position to be next to the dot */
  left: 20px;
  /* Ensure it is behind the dot */
  z-index: 1;
  /* Prevent the icon container from growing */
  flex: 0 0 auto;
  /* Add space between the icon and the text */
  margin-right: 20px;
  padding-left: 10px;
}

.timeline-item {
  position: relative;
  width: 100%;
  margin-top: 45px;
  margin-bottom: 45px;
  padding-right: 5%;
  /* padding-top aligns text and icon */
  padding-top: 15px;
  box-sizing: border-box;
}

.timeline-number {
  position: absolute;
  top: 31px;
  /* left puts the text to the left of the line */
  left: -95px;
  font-size: 1em;
  color: var(--primary-color);
  font-weight: bold;
}

.timeline-content {
  display: flex;
  align-items: center;
  padding-left: 25px;
  position: relative;
  z-index: 2;
  /* Add margin to create space between the line and the items */
  margin-left: 80px;
}

.timeline-text {
  flex: 1;
  /* Allow the text container to grow */
}

.timeline-content p {
  margin: 5px 0;
  font-size: 1.2em;
  color: var(--text-color);
}