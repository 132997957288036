.about {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  background: #f9f9f9;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding-top: 2%;
  padding-bottom: 2%;
  min-height: 100vh;
}

.about-content {
  display: flex;
  align-items: center;
}

.about-image {
  flex: 0 0 auto;
  margin-right: 60px;
  padding-left: 5%;
}

.profile-picture {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
}

.about-text {
  padding-right: 10%;
  flex: 1;
  font-size: 1.2em;
}

/* New styles for responsive design */
@media (max-width: 900px) {
  .about-content {
    flex-direction: column;
    align-items: center;
  }

  .about-text {
    padding-left: 10%;
  }

  .about-image {
    margin-bottom: 20px;
    padding-left: 0;
    margin-right: 0;
  }
}