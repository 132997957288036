.header {
  height: 100vh;
  background: #333;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
}

.header-content {
  max-width: 800px;
  margin-top: 60px;
  padding-top: 60px;
}

.header h1 {
  font-size: 3em;
  margin: 0;
}

.header p {
  font-size: 1.5em;
  margin: 10px 0;
}

nav {
  width: 100%;
  padding: 10px 0;
  z-index: 1000;
  position: absolute;
  top: 0;
  left: 0;
}

nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}

nav ul li {
  margin: 0 15px;
}

nav a {
  color: #fff;
  text-decoration: none;
  font-size: 1.2em;
}

nav a:hover {
  opacity: 0.4;
}